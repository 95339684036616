import React from 'react';
import styles from './about.module.scss'

const About = () => {
    return (
        <div className={styles.root}>
           <p>
               Khimmaster LLC was established in 2012 by Mr. Manvel Gabrielyan with a vision of preserving of the most valuable natural resources of motherland – water. Through thorough research and many meetings with leading companies in the sphere of wastewater treatment worldwide a decision was made to cooperate with Czech company Topolwater which has more than 40 years of experience in the field of designing, producing and installing Wastewater Treatment Plants (WWTP) in strict European market.
               <br/>
               <br/>
               In 2014 the first locally produced plant was installed in the Southern region of Armenia. Despite the lack of interest and much governal enforcement of environmental laws, the company continually invested in the research and grew it's production capacities.
               <br/>
               <br/>
               In 2017 our head of engineering department was hired, who by then had more than 25 years of experience in the filed of wastewater and water treatment abroad, which started a new cycle in our production. A new technology was implemented in the production which enabled us to make the plants more cost-efficient.
               <br/>
               <br/>
               As of today Khimmaster LLC has sucessfully produced and installed more than 350 WWTP all over the country. The sizes of installed WWTP vary from a single household to whole villages. The company is continually investing in it's production facilities and technologies striving to bring the best and proven technologies to the local market, all while constantly exploring new possibilities and testing new technologies.
               <br/>
               <br/>
               Besides domestic WWTP we also offer treatment solutions for food industry, diaries, fish farms. We are cooperating with local universities, leading specialists in their fields, inviting experts from all over the world, visiting Expos and leading producers in their respective fields.
               <br/>
               <br/>
               Since 2016 Khimmaster LLC has been working with Paneltim NV, a Belgian producer of unique plastic sheets which has given numerous advantages to the WWTP we produce. Along with WWTP we also produce tanks for drinking water, irrigation, galvanic processes, fish farming, diary etc.
               <br/>
               <br/>
               We offer high quality production backed up by decades of experience in design, production and servicing.
               <br/>
               <br/>
               Our goal is to never settle for the good product but bring the best, up to date solutions to the local market.
           </p>
        </div>
    );
};

export default About;