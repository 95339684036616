const FACEBOOK_ACCOUNT = 'https://www.facebook.com/profile.php?id=100038463386333';
const INSTA_ACCOUNT = 'https://www.instagram.com/khimmaster_am?igsh=MTZ1OXRubnRmNTlvYQ==';
const PHONE_NUMBER_ONE = '+374 33 48 48 33';
const PHONE_NUMBER_TWO = '';
const PHONE_NUMBER_FORMATTED_ONE = '+374 33 48 48 33';
const PHONE_NUMBER_FORMATTED_TWO = '';
const ADDRESS = 'address_details';
const COMPANY_EMAIL = 'info@wastewater.am';
const TIME = 'working-hours'


export {
    FACEBOOK_ACCOUNT,
    INSTA_ACCOUNT,
    PHONE_NUMBER_ONE,
    PHONE_NUMBER_TWO,
    PHONE_NUMBER_FORMATTED_ONE,
    PHONE_NUMBER_FORMATTED_TWO,
    ADDRESS,
    COMPANY_EMAIL,
    TIME
};
